<template>
    <div>
        <vs-table :data="['test', 'test']">
            <template slot="header">
                <h3>{{$t('home.editBookingOptionForm.finalPricesTable.header')}}</h3>
            </template>
            <template slot="thead">
                <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.pricing')}}</vs-th>
                <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.range')}}</vs-th>
                <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.beforeTax')}}</vs-th>
                <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.tax')}}</vs-th>
                <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.totalPrice')}}</vs-th>
            </template>
            <template v-for="(priceRange, index) in finalPrices">
                <vs-tr :key="`test-${index}`">
                    <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.rangeManPrice')}}</vs-td>
                    <vs-td>{{priceRange.from_first_input_count}} {{$t('to')}} {{ priceRange.to_first_input_count ? priceRange.to_first_input_count : '∞' }}</vs-td>
                    <vs-td>{{priceRange.first_input_price}} {{$t('SAR')}}</vs-td>
                    <vs-td>ssss {{$t('SAR')}}</vs-td>
                    <vs-td>ssss {{$t('SAR')}}</vs-td>
                </vs-tr>
                <vs-tr :key="`testssss-${index}`">
                    <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.rangeWomanPrice')}}</vs-td>
                    <vs-td>{{priceRange.from_second_input_count}} {{$t('to')}} {{ priceRange.to_second_input_count ? priceRange.to_second_input_count : '∞' }}</vs-td>
                    <vs-td>{{priceRange.second_input_price}} {{$t('SAR')}}</vs-td>
                    <vs-td>ssss {{$t('SAR')}}</vs-td>
                    <vs-td>ssss {{$t('SAR')}}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </div>
</template>

<script>
export default {
    name: 'final-prices-table',
    props: {
        bookingOption: {
            type: Object,
            defult: null
        },
    },
    data () {
        return {
            finalPrices: [
                {
                    from_first_input_count: 1,
                    to_first_input_count: 9,
                    first_input_price: 100,
                    from_second_input_count: 1,
                    to_second_input_count: 9,
                    second_input_price: 100,
                },
                {
                    from_first_input_count: 10,
                    to_first_input_count: 19,
                    first_input_price: 90,
                    from_second_input_count: 10,
                    to_second_input_count: 19,
                    second_input_price: 90,
                },
                {
                    from_first_input_count: 20,
                    to_first_input_count: null,
                    first_input_price: 80,
                    from_second_input_count: 20,
                    to_second_input_count: null,
                    second_input_price: 80,
                },
            ]
        }
    },
    methods: {

    },
    watch: {
        'bookingOption.man_price': function(newVal) {
            console.log('man price updaed!!', newVal);
        }
    }
}
</script>