<template>
    <div>
        <div v-if="selectedBookingOption">
            <vs-popup class="holamundo " :title="actionType == 'addBookingOption' ? $t('home.editBookingOptionForm.addBookingOption') : actionType == 'editBookingOption' ? $t('home.editBookingOptionForm.editBookingOption') +' | '+ selectedBookingOption.name : $t('home.editBookingOptionForm.duplicateBookingOption') +' | '+ selectedBookingOption.name" :active.sync="isActive" @close="closePopup">
                
                <!-- BOOKING OPTION DETAILS -->
                <div class="grid lg:grid-cols-2 gap-5 pt-2">



                    <div class="col-span-2">
                        <vue-upload-multiple-image
                            :idUpload="imagesUploadId"
                            :showEdit="false"
                            @upload-success="uploadImageSuccess"
                            @before-remove="beforeRemove"
                            @mark-is-primary="primaryMarked"
                            :popupText="$t('VueUploadMultipleImages.popupText')"
                            :primaryText="$t('VueUploadMultipleImages.primaryText')"
                            :markIsPrimaryText="$t('VueUploadMultipleImages.markIsPrimaryText')"
                            :browseText="$t('VueUploadMultipleImages.browseText')"
                            dragText=""
                            :maxImage="20"
                            :data-images="images" />
                    </div>




                    <vs-input :label="$t('home.editBookingOptionForm.name')" v-model="selectedBookingOption.name" class="w-full required col-span-2 lg:col-span-1" autocomplete="off"/>
                    <vs-input :label="$t('home.editBookingOptionForm.description')" v-model="selectedBookingOption.description" class="w-full col-span-2 lg:col-span-1"/>
                    <vs-textarea :label="$t('home.editBookingOptionForm.detailedDescription')" height="150px" v-model="selectedBookingOption.detailed_description" class="w-full col-span-2"/>


                    <div class="col-span-2">
                        <span class="text-md">{{$t('home.editBookingOptionForm.minMaxCountTitle')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="selectedBookingOption.has_min_max_men_women_count" vs-name="selectedBookingOption.has_min_max_men_women_count" :vs-value="false">{{$t('home.editBookingOptionForm.selectText.no')}}</vs-radio>
                            <vs-radio v-model="selectedBookingOption.has_min_max_men_women_count" vs-name="selectedBookingOption.has_min_max_men_women_count" :vs-value="true">{{$t('home.editBookingOptionForm.selectText.yes')}}</vs-radio>
                        </div>
                    </div>
                    <div class="col-span-2 grid lg:grid-cols-2 gap-3" v-if="selectedBookingOption.has_min_max_men_women_count">
                        <vs-input type="number" :label="$t('home.editBookingOptionForm.minimumMenCount')" v-model="selectedBookingOption.men_count_min" class="w-full col-span-2 lg:col-span-1"/>
                        <vs-input type="number" :label="$t('home.editBookingOptionForm.maximumMenCount')" v-model="selectedBookingOption.men_count_max" class="w-full col-span-2 lg:col-span-1"/>
                        <vs-input type="number" :label="$t('home.editBookingOptionForm.minimumWomenCount')" v-model="selectedBookingOption.women_count_min" class="w-full col-span-2 lg:col-span-1"/>
                        <vs-input type="number" :label="$t('home.editBookingOptionForm.maximumWomenCount')" v-model="selectedBookingOption.women_count_max" class="w-full col-span-2 lg:col-span-1"/>
                    </div>

                    <div class="col-span-2 lg:col-span-1">
                        <span class="text-md">{{$t('home.editBookingOptionForm.availability')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="selectedBookingOption.availability" vs-name="selectedBookingOption.availability" vs-value="available">{{$t('home.editBookingOptionForm.selectText.available')}}</vs-radio>
                            <vs-radio v-model="selectedBookingOption.availability" vs-name="selectedBookingOption.availability" vs-value="hidden">{{$t('home.editBookingOptionForm.selectText.hidden')}}</vs-radio>
                        </div>
                    </div>
                    <div class="col-span-2 lg:col-span-1">
                        <span class="text-md">{{$t('home.editBookingOptionForm.bookingOptionType')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="selectedBookingOption.availability_type" vs-name="selectedBookingOption.availability_type" vs-value="size_based">{{$t('home.editBookingOptionForm.sizeBased')}}</vs-radio>
                            <vs-radio v-model="selectedBookingOption.availability_type" vs-name="selectedBookingOption.availability_type" vs-value="rooms_based">{{$t('home.editBookingOptionForm.roomsBased')}}</vs-radio>
                        </div>
                    </div>

                    <div v-if="actionType == 'duplicateBookingOption'" class="col-span-2 grid lg:grid-cols-2 gap-3">
                        <div v-if="agentVenues.length > 0" class="col-span-2">
                            <span class="text-md">{{$t('home.editBookingOptionForm.branches')}}</span>
                            <ul>
                                <li v-for="venue in agentVenues" :key="venue.id" class="mt-2 mr-6 mb-5">
                                    <vs-checkbox v-model="selectedBranches" :vs-value="venue.id">{{venue.name}} {{venue.id == currentVenueId? '(الفرع الحالي)' : ''}}</vs-checkbox>
                                    <ul class="flex flex-wrap mt-2 ml-5" v-if="selectedBookingOption.availability_type == 'rooms_based' && selectedBranches.includes(venue.id)">
                                        <li v-for="room in venue.rooms" :key="room.id" class="mt-2 mr-5">
                                            <vs-checkbox v-model="venue.selectedRooms" :vs-value="room.id">{{room.name}}</vs-checkbox>
                                        </li>
                                    </ul>
                                    <ul class="flex flex-wrap mt-2 ml-5" v-if="selectedBookingOption.availability_type == 'size_based' && selectedBranches.includes(venue.id)">
                                        <li class="mt-2 mr-5">
                                            <vs-input type="number" :label="$t('home.editBookingOptionForm.sizeNote')+':'" v-model="venue.bookings_size" class="w-full"/>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-else class="col-span-2 grid lg:grid-cols-2 gap-3">
                        <vs-input v-if="selectedBookingOption.availability_type == 'size_based'" type="number" :label="$t('home.editBookingOptionForm.sizeNote')" v-model="selectedBookingOption.bookings_size" class="w-full col-span-2 lg:col-span-1"/>
                        <div v-if="selectedBookingOption.availability_type == 'rooms_based'" class="col-span-2">
                            <span class="text-md">{{$t('home.editBookingOptionForm.chooseRooms')}}</span>
                            <div class="mt-2 w-full flex flex-wrap space-x-3 rtl:space-x-reverse">
                                <vs-checkbox class="mt-1" v-for="room in roomsList" :key="room.id" v-model="selectedRooms" :vs-value="room.id">{{room.name}}</vs-checkbox>
                            </div>
                        </div>
                    </div>


                    <div class="col-span-2 pt-10" v-if="showDate">
                        <span class="text-sm">{{$t('home.editBookingOptionForm.eventDateShowReason', {timeBasedEventTypes: timeBasedEventTypes.map(eventType => eventType.display_name).join(', ')})}}</span>
                        <div class="mt-2 flex space-x-3 rtl:space-x-reverse">
                            <v-date-picker
                                class="flex w-full"
                                v-model="optionDateRange"
                                mode="datetime"
                                is-range
                                color="orange"
                                :masks="dateFormat"
                                :min-date="minDate"
                                :max-date="maxDate"
                            >
                                <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="flex w-full">

                                    <vs-input
                                        :label="$t('home.editBookingOptionForm.eventStartDate')"
                                        class="flex-grow py-1 bg-gray-100 border rounded w-full pr-1"
                                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                        :value="inputValue.start"
                                        v-on="inputEvents.start"
                                        icon="today"
                                    />

                                    <vs-input
                                        :label="$t('home.editBookingOptionForm.eventEndDate')"
                                        class="flex-grow py-1 bg-gray-100 border rounded w-full"
                                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                        :value="inputValue.end"
                                        v-on="inputEvents.end"
                                        icon="event"
                                    />
                                </div>
                                </template>
                            </v-date-picker>
                            <div class="pt-2" v-if="optionDateRange.start || optionDateRange.end">
                                <vs-button class="my-5 mx-1" type="border" icon-pack="feather" icon="icon-trash" @click="clearDateRange()"></vs-button>
                            </div>
                        </div>
                        <span class="text-sm">{{$t('home.editBookingOptionForm.eventDatesNote')}}</span>
                    </div>
                    <!-- Booking Option Start And End Date -->





                </div>
                <!-- // BOOKING OPTION DETAILS // -->

                <hr class="my-10">

                <!-- BOOKING OPTION CATEGORIZE -->
                <div class="grid lg:grid-cols-2 gap-5">

                    <div class="col-span-2">
                        <span class="text-md">{{$t('home.editBookingOptionForm.chooseEventTypes')}}</span>
                        <div class="mt-2 w-full flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-checkbox class="mt-1" v-for="eventType in eventTypesList" :key="eventType.id" v-model="selectedEventTypes" :vs-value="eventType.id">{{eventType.display_name}}</vs-checkbox>
                        </div>
                    </div>

                    <!-- categories and types -->


                </div>
                <!-- // BOOKING OPTION CATEGORIZE // -->

                <hr class="my-10">

                <!-- BOOKING OPTION PRICING -->
                <div class="grid lg:grid-cols-2 gap-5 pb-10">
                    <div class="col-span-2">
                        <span class="text-md">{{$t('home.editBookingOptionForm.pricingType')}}</span>
                    </div>
                    <div class="col-span-2">
                        <vs-radio v-model="selectedBookingOption.pricing_type" vs-name="selectedBookingOption.pricing_type" vs-value="fixed">{{$t('home.editBookingOptionForm.selectText.fixed')}}</vs-radio>
                        <div class="mx-5">
                            <small class="gray-note">{{$t('home.editBookingOptionForm.selectText.fixedNote')}}</small>
                        </div>
                        
                    </div>
                    <div class="col-span-2">
                        <vs-radio v-model="selectedBookingOption.pricing_type" vs-name="selectedBookingOption.pricing_type" vs-value="per_person">{{$t('home.editBookingOptionForm.selectText.per_person')}}</vs-radio>
                        <div class="mx-5">
                            <small class="gray-note">{{$t('home.editBookingOptionForm.selectText.perPersonNote')}}</small>
                        </div>
                    </div>
                    <div class="col-span-2">
                        <vs-radio v-model="selectedBookingOption.pricing_type" vs-name="selectedBookingOption.pricing_type" vs-value="per_person_range">{{$t('home.editBookingOptionForm.selectText.perPersonRange')}}</vs-radio>
                        <div class="mx-5">
                            <small class="gray-note">{{$t('home.editBookingOptionForm.selectText.perPersonRangeNote')}}</small>
                        </div>
                    </div>
                    <div class="col-span-2">
                        <vs-radio v-model="selectedBookingOption.pricing_type" vs-name="selectedBookingOption.pricing_type" vs-value="fixed_range">{{$t('home.editBookingOptionForm.selectText.fixedRange')}}</vs-radio>
                        <div class="mx-5">
                            <small class="gray-note">{{$t('home.editBookingOptionForm.selectText.fixedRangeNote')}}</small>
                        </div>
                    </div>
                    <div class="col-span-2 grid lg:grid-cols-2 gap-3">
                        <vs-input class="w-full col-span-2 lg:col-span-1" v-if="selectedBookingOption.pricing_type == 'fixed'" type="number" :label="$t('home.editBookingOptionForm.price')" v-model="selectedBookingOption.price"/>
                        <vs-input class="w-full col-span-2 lg:col-span-1" v-if="selectedBookingOption.pricing_type == 'per_person'" type="number" :label="$t('home.editBookingOptionForm.manPrice')" v-model="selectedBookingOption.man_price"/>
                        <vs-input class="w-full col-span-2 lg:col-span-1" v-if="selectedBookingOption.pricing_type == 'per_person'" type="number" :label="$t('home.editBookingOptionForm.womanPrice')" v-model="selectedBookingOption.woman_price"/>
                        <vs-input class="w-full col-span-2 lg:col-span-1" v-if="selectedBookingOption.pricing_type == 'per_person' || selectedBookingOption.pricing_type == 'per_person_range'" type="number" :label="$t('home.editBookingOptionForm.minimumPrice')" v-model="selectedBookingOption.minimum_price"/>
                    </div>

                    
                    
                    <div class="col-span-2" v-if="selectedBookingOption.pricing_type == 'per_person_range' || selectedBookingOption.pricing_type == 'fixed_range'">
                        <div class="vx-row mb-5">
                            <vs-alert color="warning" icon="sms" :active.sync="rangePricingAlert" closable close-icon="close" class="h-full mb-5 py-2 pr-2">
                                <h5 class="mb-3">{{$t('home.editBookingOptionForm.rangePricing.alert.header')}}</h5>
                                <p>{{$t('home.editBookingOptionForm.rangePricing.alert.line1')}}</p>
                                <p v-if="selectedBookingOption.pricing_type == 'per_person_range'">{{$t('home.editBookingOptionForm.rangePricing.alert.line2_per_person')}}</p>
                                <p v-if="selectedBookingOption.pricing_type == 'fixed_range'">{{$t('home.editBookingOptionForm.rangePricing.alert.line2_fixed')}}</p>
                            </vs-alert>
                        </div>
                        <div v-for="(rangePricing, index) in selectedBookingOption.range_pricing" :key="index">
                            <div class="grid grid-cols-2 lg:grid-cols-9">
                                <div class="cos-span-1 lg:col-span-2">
                                    <vs-input type="number" class="w-11/12" v-model="rangePricing.men_count" :label="$t('servicesForm.groupPricing.menCount')" autocomplete="off"/>
                                </div>
                                <div class="cos-span-1 lg:col-span-2">
                                    <vs-input type="number" class="w-11/12" v-model="rangePricing.man_price" :label="$t('servicesForm.groupPricing.manPrice')" autocomplete="off"/>
                                </div>
                                <div class="cos-span-1 lg:col-span-2">
                                    <vs-input type="number" class="w-11/12" v-model="rangePricing.women_count" :label="$t('servicesForm.groupPricing.womenCount')" autocomplete="off"/>
                                </div>
                                <div class="cos-span-1 lg:col-span-2">
                                    <vs-input type="number" class="w-11/12" v-model="rangePricing.woman_price" :label="$t('servicesForm.groupPricing.womanPrice')" autocomplete="off"/>
                                </div>
                                <div class="mt-1 col-span-1 flex justify-end">
                                    <vs-button @click="removeRow(index)" color="danger" icon="delete" class="m-0 mt-5"></vs-button>
                                </div>
                            </div>
                            <hr>

                        </div>
                        <div class="mt-2 w-full flex justify-end">
                            <vs-button @click="addRow" color="success" icon="add" class="m-0 mt-5 mr-2">{{$t('servicesForm.groupPricing.addRow')}}</vs-button>
                        </div>
                    </div>




                    <div class="col-span-2 lg:col-span-1">
                        <span class="text-md">{{$t('home.editBookingOptionForm.taxingStatus')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="selectedBookingOption.is_taxable" vs-name="selectedBookingOption.is_taxable" :vs-value="1">{{$t('home.editBookingOptionForm.taxable')}}</vs-radio>
                            <vs-radio v-model="selectedBookingOption.is_taxable" vs-name="selectedBookingOption.is_taxable" :vs-value="0">{{$t('home.editBookingOptionForm.nonTaxable')}}</vs-radio>
                        </div>
                    </div>
                    <div class="col-span-2 lg:col-span-1">
                        <div v-if="selectedBookingOption.is_taxable">
                            <span class="text-md">{{$t('home.editBookingOptionForm.taxingIncluding')}}</span>
                            <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                                <vs-radio v-model="selectedBookingOption.tax_included" vs-name="selectedBookingOption.tax_included" vs-value="included">{{$t('home.editBookingOptionForm.taxIncluded.included')}}</vs-radio>
                                <vs-radio v-model="selectedBookingOption.tax_included" vs-name="selectedBookingOption.tax_included" vs-value="not-included">{{$t('home.editBookingOptionForm.taxIncluded.notIncluded')}}</vs-radio>
                            </div>
                        </div>
                    </div>


                    <!-- pricing table (make it external component) -->
                    <!-- <div class="col-span-2">
                        <final-prices-table :bookingOption="selectedBookingOption" />
                    </div> -->

                    <div class="col-span-2 lg:col-span-1">
                        <span class="text-md">{{$t('home.editBookingOptionForm.hasSeasonalPricing')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="selectedBookingOption.has_seasonal_pricing" vs-name="selectedBookingOption.has_seasonal_pricing" vs-value="1">{{$t('home.editBookingOptionForm.selectText.yes')}}</vs-radio>
                            <vs-radio v-model="selectedBookingOption.has_seasonal_pricing" vs-name="selectedBookingOption.has_seasonal_pricing" vs-value="0">{{$t('home.editBookingOptionForm.selectText.no')}}</vs-radio>
                        </div>
                    </div>
                    <div class="col-span-2 lg:col-span-1">
                        <span class="text-md">{{$t('home.editBookingOptionForm.hasDaysPricing')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="selectedBookingOption.has_days_pricing" vs-name="selectedBookingOption.has_days_pricing" vs-value="1">{{$t('home.editBookingOptionForm.selectText.yes')}}</vs-radio>
                            <vs-radio v-model="selectedBookingOption.has_days_pricing" vs-name="selectedBookingOption.has_days_pricing" vs-value="0">{{$t('home.editBookingOptionForm.selectText.no')}}</vs-radio>
                        </div>
                    </div>

                    <div class="col-span-2" v-if="actionType == 'duplicateBookingOption'">
                    <div class="container mt-2">
                        <div class="vx-row">
                            <vs-checkbox v-model="copyCustomPricings">{{$t('home.editBookingOptionForm.copyCustomPricings')}}</vs-checkbox>
                        </div>
                        <div class="vx-row mt-0 pt-0">
                            <vs-list class="mt-0 pt-0">
                                <vs-list-item icon="info" :subtitle="$t('home.editBookingOptionForm.copyCustomPricingsNote')"></vs-list-item>
                            </vs-list>
                        </div>
                    </div>
                </div>
                </div>
                <!-- // BOOKING OPTION PRICING // -->



                <div class="col-span-2 my-10">
                    <div class="flex w-full justify-end">
                        <vs-button icon-pack="feather" icon="icon-check" @click.native="submitForm" class="mx-2">{{$t('save')}}</vs-button>
                    </div>
                </div>

            </vs-popup>
        </div>
    </div>
</template>
  
<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import FinalPricesTable from './FinalPricesTable.vue'
import moment from 'moment'
export default {
    name: 'booking-option-form-popup',
    components: {
        VueUploadMultipleImage,
        FinalPricesTable
    },
    computed: {
        isActive: {
            get() {
                return this.isBookingOptionFormActive
            },
            set(newState) {
                return newState
            }
        },
        imagesUploadId() { // images upload library had some issues with the idUpload so I had to generate a random string for it
            return Math.random().toString(16).substr(2, 32);
        },
        baseURL: () => process.env.VUE_APP_IMAGES_BASE_URL,
        isOnBoarding() {
            let pathArray = window.location.href.split('/');
            return pathArray[pathArray.length - 1] == 'on-boarding';
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        eventTypesList () {
            return this.$store.state.dataList.eventTypesList
        },
        timeBasedEventTypes() {
            return this.$store.state.dataList.eventTypesList.filter((eventTypes) => eventTypes.is_time_based)
        },
        roomsList () {
            return this.$store.state.general.roomsList;
        },
        agentVenuesState() {
            return typeof(this.$store.state.auth.agentVenues) == 'string' ? this.$store.state.auth.agentVenues != 'undefined' ?JSON.parse(this.$store.state.auth.agentVenues) : [] : this.$store.state.auth.agentVenues;
        },
    },
    props: {
        isBookingOptionFormActive:  {
            type: Boolean,
            defult: false
        },
        bookingOption: {
            type: Object,
            defult: null
        },
        actionType: { // could be: [addBookingOption, duplicateBookingOption, editBookingOption]
            type: String,
            default: 'addBookingOption'
        },
    },
    created() {
        this.$store.dispatch('dataList/fetchEventTypesList');
        this.$store.dispatch('general/getRoomsList', this.currentVenueId)
    },
    data:()=>({
        images: [],
        selectedBookingOption: null,
        selectedEventTypes: [],
        selectedRooms: [],
        rangePricingAlert: true,
        showDate: false,

        // DUPLICATE //
        selectedBranches: [],
        agentVenues: [],
        copyCustomPricings: false,
        // ** DUPLICATE **//

        // DATE RANGE //
        optionDateRange: {
            start: null,
            end: null,
        },
        minDate: "2020-01-01",
        maxDate: "2030-01-01",
        dateFormat: {
            input: 'YYYY-MM-DD HH:mm'
        },
        // ** DATE RANGE **//
    }),
    methods: {
        async submitForm() {
            this.$store.dispatch('loader/loaderOn')
            let data = {
                bookingOption: this.selectedBookingOption,
                selectedEventTypes: this.selectedEventTypes,
                selectedRooms: this.selectedRooms,
                images: this.images,

                // FOR DUPLICATE
                selectedBranches: this.selectedBranches,
                agentVenues: this.agentVenues,
                agentVenuesState: this.agentVenuesState,
                copyCustomPricings: this.copyCustomPricings,
                source: this.isOnBoarding ? 'OnBoarding' : 'Normal'
            }

            let response = null;
            if(this.actionType == 'editBookingOption')
                response = await this.$store.dispatch('bookingOptions/updateBookingOption', {venueId: this.currentVenueId, data});
            else if(this.actionType == 'duplicateBookingOption')
                response = await this.$store.dispatch('bookingOptions/duplicateBookingOption', {venueId: this.currentVenueId, data});
            else
                response = await this.$store.dispatch('bookingOptions/addBookingOption', {venueId: this.currentVenueId, data});

            if(response.status == 200) {
                this.closePopup();
                this.$vs.notify({
                    color:'success',
                    text: response.data.success_message
                })
            } else {
                let errors = response.data.errors;
                Object.keys(errors).forEach(key => {
                    this.$vs.notify({
                        color:'danger',
                        icon: 'warning',
                        text: errors[key][0]
                    })
                });
            }
            this.$store.dispatch('loader/loaderOff')
        },
        closePopup() {
            this.$emit('update:isBookingOptionFormActive', false);
        },
        // IMAGES UPLOADER //
        uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
        },
        beforeRemove (index, done, fileList) {
            var confirmationAlert = confirm("remove image")
            if (confirmationAlert == true) {
                done()
            }
            this.images = fileList;
        },
        primaryMarked(index, fileList) {
            this.images = fileList;
        },
        // ** IMAGES UPLOADER ** //

        // RANGE PRICING //
        addRow() {
            console.log(this.selectedBookingOption);
            this.selectedBookingOption.range_pricing.push({
                men_count: 0,
                man_price: 0,
                women_count: 0,
                woman_price: 0,
            });
        },
        removeRow(index) {
            this.selectedBookingOption.range_pricing.splice(index, 1)
        },
        // ** RANGE PRICING ** //

        // EVENT DATE //
        checkTimeBasedEventTypes() {
            let timeBasedEventTypesIds = this.timeBasedEventTypes.map(eventType => eventType.id);
            const timeBasedEventTypes = this.selectedEventTypes.filter(eventType => {
                return timeBasedEventTypesIds.includes(eventType);
            });
            const hasTimeBasedEventTypes = timeBasedEventTypes.length > 0;
            if(!hasTimeBasedEventTypes) {
                this.clearDateRange();
            }
            this.showDate = hasTimeBasedEventTypes;
        },
        clearDateRange() {
            this.optionDateRange = { start: null, end:null}
        }
        //** EVENT DATE **//
    },
    watch: {
        isActive(newVal) {
            if(newVal) {
                let bookingOption = JSON.parse(JSON.stringify(this.bookingOption))
                this.selectedBookingOption = bookingOption;
                if(bookingOption.event_types) this.selectedEventTypes = bookingOption.event_types.map(eventType => eventType.id); else this.selectedEventTypes = [];
                if(bookingOption.rooms) this.selectedRooms = bookingOption.rooms.map(room => room.id); else this.selectedRooms = [];
                if(bookingOption.images) this.images = bookingOption.images.map((image, index) => { return { path: this.baseURL+'/'+image ,default: index == 0 ? 1 : 0,highlight: index == 0 ? 1 : 0,caption: 'caption to display. receive', imgUrl: image}}); else this.images = [];
                if(bookingOption.range_pricing == null) this.selectedBookingOption.range_pricing = [];

                if(this.agentVenuesState) {
                    let agentVenues = [];
                    this.selectedBranches = [];
                    this.selectedBranches.push( Number(this.currentVenueId)); // auto select the current branch
                    this.agentVenuesState.forEach((venue, i) => { // TODO: make the current branch is the first venue in the array
                        agentVenues.push({
                            id: venue.id,
                            venue_id: venue.id,
                            name: venue.name,
                            rooms: venue.rooms,
                            selectedRooms: [],
                            bookings_size: bookingOption.bookings_size,
                        });
                    });
                    this.agentVenues = agentVenues;
                }

                if(bookingOption.event_start_date) this.optionDateRange = {start: this.bookingOption.event_start_date, end: this.bookingOption.event_end_date};
                this.checkTimeBasedEventTypes();
            }
        },
        'selectedBookingOption.has_min_max_men_women_count': function(newVal) {
            if(!newVal) {
                this.selectedBookingOption.men_count_min = null;
                this.selectedBookingOption.men_count_max = null;
                this.selectedBookingOption.women_count_min = null;
                this.selectedBookingOption.women_count_max = null;
            }
        },
        'selectedBookingOption.is_taxable': function(newVal) {
            if(!newVal) {
                this.selectedBookingOption.tax_included = 'not-included';
            }
        },
        selectedEventTypes: function() {
            this.checkTimeBasedEventTypes();
        },
        optionDateRange: function(newVal) {
            this.selectedBookingOption.event_start_date = moment(newVal.start).format(this.dateFormat.input);
            this.selectedBookingOption.event_end_date = moment(newVal.end).format(this.dateFormat.input);
        },
    }
  }
  </script>
  

  