<template>
<div>
  <div class="flex w-full justify-end my-3">
    <vs-button icon="add" @click="addBookingOption">{{ $t("Add")}}</vs-button>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
    <div v-for="bookingOption in bookingOptions" :key="bookingOption.id">
      <vs-card fixed-height>
        <div slot="media">
            <img :src="imagesURL+'/'+ (bookingOption.images.length > 0 ? bookingOption.images[0] : bookingOption.main_image)" class="object-cover h-48 rounded-t-lg">
        </div>
        <div slot="extra-content">
          <!-- EDIT & ACTION BUTTONS -->
          <div class="container">
            <div class="vx-row justify-between px-2 pt-2">
              <div>
                <vs-tooltip class="mt-2" text="This booking option is available to the customers" v-if="bookingOption.availability == 'available'">
                  <vs-chip color="success">
                      {{$t('home.editBookingOptionForm.bookingOptionAvailable')}}
                  </vs-chip>
                </vs-tooltip>
                <vs-tooltip v-else text="his booking option is hidden from the customers">
                  <vs-chip color="warning">
                      {{$t('home.editBookingOptionForm.bookingOptionHidden')}}
                  </vs-chip>
                </vs-tooltip>
              </div>
              <div class="flex">
                <vs-dropdown class="mx-1" vs-trigger-click>
                  <vs-button color="dark" type="border" icon="more_horiz"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item v-if="bookingOption.has_seasonal_pricing">
                      <vs-button class="px-2" type="flat" color="dark" icon-pack="FA5" icon="fas fa-dollar-sign" :to="{ name: 'custom-pricings', params: { option_id: bookingOption.id }, hash: '#seasonalPricings'}">{{$t('home.seasonalPricings')}}</vs-button>
                    </vs-dropdown-item>
                    <vs-dropdown-item v-if="bookingOption.has_days_pricing">
                      <vs-button class="px-2" type="flat" color="dark" icon-pack="FA5" icon="fas fa-dollar-sign" :to="{ name: 'custom-pricings', params: { option_id: bookingOption.id }, hash: '#daysPricings'}">{{$t('home.daysPricings')}}</vs-button>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <vs-button class="mt-2 px-2" @click="duplicateBookingOption(bookingOption)" icon-pack="FA5" icon="far fa-copy" type="flat" color="dark">{{$t('home.editBookingOptionForm.duplicateBookingOption')}}</vs-button>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <vs-button class="mt-2 px-2" @click="deleteBookingOptionPrompt(bookingOption)" icon-pack="FA5" icon="far fa-trash" type="flat" color="danger">{{$t('home.editBookingOptionForm.deleteBookingOption')}}</vs-button>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button color="primary" type="border" icon="edit" @click="editBookingOption(bookingOption)"></vs-button>
              </div>
            </div>
            <hr>
          </div>
          <!--// EDIT & ACTION BUTTONS //-->

          <!-- BOOKING OPTION DATA (NAME - DESCRIPTION.. ETC) -->
          <div class="">
            <div class="vx-row px-2 py-5">
              <div class="vx-col w-2/3">
                <span>{{bookingOption.name}}</span> <br /> <small class="font-medium">{{bookingOption.description}}</small>
              </div>
              <div class="vx-col w-1/3">
                <vs-tooltip :text="$t('home.editBookingOptionForm.taxApplied')">
                  <small class="font-medium">{{$t('home.editBookingOptionForm.thePrice')}}</small> <br /> <span>{{bookingOption.is_taxable_final ? Math.round((bookingOption.price * 1.15)).toLocaleString() : bookingOption.price.toLocaleString()}} {{$t('SAR')}}</span>
                </vs-tooltip>
              </div>
            </div>
          </div>
          <!-- BOOKING OPTION DATA (NAME - DESCRIPTION.. ETC) -->
          <div class="container mt-2 text-center flex-1 justify-center items-center" v-if="bookingOption.event_start_date_str || bookingOption.bookings_count_per_size_str">
            <hr>
            <div class="px-2 mt-5">
              <div class="" style="background: #b3b3b3; border-radius: 20px; color: white; text-align: center">
                <span>{{bookingOption.bookings_count_per_size_str}}</span>
              </div>
            </div>
            <div class="px-2 mt-5" v-if="bookingOption.event_start_date_str">
              <div class="" style="border-radius: 20px; text-align: center">
                <span>{{bookingOption.event_start_date_str}}</span>
              </div>
            </div>
          </div>
        </div>
      </vs-card>
    </div>
  </div>
  <vs-pagination class="mt-5" :total="Math.ceil(tableConfig.totalPages)" v-model="currentx" @input="handleChangePage"></vs-pagination>



  <!-- POPUP COMPONENTS -->
  <booking-option-form-popup :isBookingOptionFormActive.sync="isBookingOptionFormActive" :bookingOption="selectedBookingOption" :actionType="actionType"/>

  <vs-prompt v-if="selectedBookingOption" :title="$t('home.editBookingOptionForm.delete.promptHeader')" color="danger" @accept="deleteBookingOption" @cancel="selectedBookingOption = null" @close="selectedBookingOption = null" :accept-text="$t('home.editBookingOptionForm.delete.accept')" :cancel-text="$t('home.editBookingOptionForm.delete.cancel')" :active.sync="deleteBookingOptionActive">
    {{$t('home.editBookingOptionForm.delete.message', { bookingOptionName: selectedBookingOption.name })}}
  </vs-prompt>

  <!-- // POPUP COMPONENTS // -->
</div>
</template>

<script>
import BookingOptionFormPopup from '@/components/BookingOptions/BookingOptionFormPopup.vue';
export default {
  name: "booking-options-list",
  data () {
    return {
      currentx: 1,
      tableConfig: {
        totalPages: 0,
        perPage: 12,
        from: 1
      },
      isBookingOptionFormActive: false,
      selectedBookingOption: null,
      actionType: null,
      deleteBookingOptionActive: false,
    }
  },
  components: {
    BookingOptionFormPopup
  },
  computed: {
    imagesURL: () => process.env.VUE_APP_IMAGES_BASE_URL,
    isOnBoarding() {
      let pathArray = window.location.href.split('/');
      return pathArray[pathArray.length - 1] == 'on-boarding';
    },
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    bookingOptions () {
        return this.$store.state.bookingOptions.bookingOptions
    },
  },
  created () {
    this.getBookingOptions();
    this.$store.dispatch('loader/loaderOff')
  },
  methods: {
    async getBookingOptions(page=1) {
      let query = {
        page: page,
        per_page: this.tableConfig.perPage
      }

      this.$store.dispatch('loader/loaderOn')
        
      const response = await this.$store.dispatch('bookingOptions/getBookingOptions', {venueId: this.currentVenueId, query})

      this.$store.dispatch('loader/loaderOff')

      this.tableConfig.totalPages = response.data.meta.last_page
      this.tableConfig.from = response.data.meta.from
    },
    handleChangePage(data) {
      this.getBookingOptions(data);
    },
    addBookingOption() {
      this.selectedBookingOption = {
        pricing_type : 'fixed',
        has_min_max_men_women_count: false,
        is_taxable : 1,
        tax_included: 'included',
        price : 0,
        man_price : 0,
        woman_price : 0,
        availability: 'available',
        availability_type: 'size_based',
        bookings_size: 1,
        range_pricing: [],
        has_seasonal_pricing: 0,
        has_days_pricing: 0
      };
      this.isBookingOptionFormActive = true;
      this.actionType = 'addBookingOption';
    },
    editBookingOption(bookingOption) {
      this.actionType = 'editBookingOption'
      this.selectedBookingOption = bookingOption;
      this.isBookingOptionFormActive = true;
    },
    duplicateBookingOption(bookingOption) {
      this.actionType = 'duplicateBookingOption';
      this.selectedBookingOption = bookingOption;
      this.isBookingOptionFormActive = true;
    },
    deleteBookingOptionPrompt(bookingOption) {
      this.selectedBookingOption = bookingOption;
      this.deleteBookingOptionActive = true;
    },
    async deleteBookingOption() {
      let data = {
        bookingOption: this.selectedBookingOption,
        source: this.isOnBoarding ? 'OnBoarding' : 'Normal'
      }

      this.$store.dispatch('loader/loaderOn')
      
      const response = await this.$store.dispatch('bookingOptions/deleteBookingOption', {venueId: this.currentVenueId, data});
      
      this.$store.dispatch('loader/loaderOff')

      if(response.status == 200) {
        this.$vs.notify({
          color:'success',
          text: response.data.success_message
        })
      }

    }
  }
}
</script>

<style>
.vs-tooltip {
  z-index: 99999 !important;
}
hr{
  height: 0.5px;
  border-color: rgb(255, 255, 255) !important;
  opacity: 0.5;
  margin-top: 10px;
}
</style>
